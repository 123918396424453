@import '~@blueprintjs/core/lib/scss/variables';

:root {
  --table-th-border-shadow: inset 1px 0 0 0 rgba(16, 22, 26, 0.15);
  --table-odd-bg: rgba(191, 204, 214, 0.15);
  --table-hover-bg: rgba(191, 204, 214, 0.3);
  --table-first-cell-border-shadow: inset 0 1px 0 0 rgba(16, 22, 26, 0.15);
  --table-cell-border-shadow: inset 1px 1px 0 0 rgba(16, 22, 26, 0.15);
  --table-cell-border-color: 1px solid #10161a26;
}
