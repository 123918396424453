
.bp3-select-popover.scrollable .bp3-menu {
  max-height: 310px;
  overflow-y: auto;
}


.bp3-select-popover.scrollable-tall .bp3-menu {
  max-height: 510px;
  overflow-y: auto;
}