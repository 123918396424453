
.change-card-option-alert.bp3-dialog.bp3-alert .bp3-alert-footer {
  display: none;
}

.change-card-option-alert .bp3-alert-contents {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.change-card-option-alert .bp3-alert-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.change-card-option-alert .bp3-popover-content .bp3-menu {
  max-height: 300px;
  max-width: 250px;
  overflow: auto;
  width: 250px;
}